@import "variables";
@import '../../../node_modules/bootstrap/scss/bootstrap';

.login-background {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    background-color: $background-login !important;
    position: absolute;
}

.login-img-container {
    // opacity: 0.8;
    overflow: hidden;
    position: fixed;
}

.login-img {
    height: 50rem;
    width: 90rem;
}

.login-logo {
    display: flex;
    justify-content: center;
}

.login-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    //text-align: center;
    //color:#ffffff;
    width: 20rem;
    //height: 14rem;
}

.header {
    height: $header-height;
    margin: $header-margin;
    //margin-right: 5.25rem;
    width: calc(#{$header-width} - #{2 * $header-margin});
    background-color: $background-primary;
    display: flex;
    //align-items: stretch;
    //align-content: stretch;
    justify-content: space-between;
}

.header-logo {
    height: 100%;
    width: $header-logo-width;
    //float: left
}

.header-title {
    font-size: 1.25rem;
    font-weight: bolder;
    color: $main-text-color;
    display: inline-flex;
    max-width: calc(#{$header-width} - #{$header-user-card-width} - #{$header-logo-width} - #{2 * $header-title-margin});
    height: 100%;
    margin-left: $header-title-margin;
    margin-right: $header-title-margin;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

.header-user-card {
    width: $header-user-card-width;
    //height: calc(100% - #{ 2 * $header-margin});
    height: 100%;
    //float: right;
    display: flex;
    justify-content: center;
    align-content: stretch;
    border-style: solid;
    border-color: $electrans-color2;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    font-weight: bold;
    color: $electrans-color2;
}

.header-text-user-card {
    display: flex;
    text-align: center;
    //justify-content: center;
    align-items: center;
}

.header-button-user-card {
    display: flex;
    align-items: center;
    //height: $header-height;
    //width: calc(#{$header-user-card-width} * 0.2);
    //margin-bottom: 1.5rem;
    color: $electrans-color2 !important;
}

.sub-header {
    width: $header-width;
    height: $sub-header-height;
    background-color: $background-secondary;
    top: calc(#{$header-height} + #{$header-margin * 2});
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu-button {
    height: 100%;
    //float: left;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: $background-primary !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.breadcrumb {
    margin-top: 0.1rem;
    height: 1rem;
    font-size: large;
    margin-left: 0.5rem;
    //margin-bottom: 0 !important;
    font-weight: bold;
}

.breadcrumb-item.active, .breadcrumb-item.active::before{
    color: $background-primary !important;
}

.menubar {
    //display: none;
    left: calc(0rem - $sidebar-width);
    background-color: $background-secondary;
    width: $sidebar-width;
    height: auto;
    position: absolute;
    z-index: 2;
    animation-name: closeMenuAnimation;
    animation-duration: 0.25s;
}

.menubar.active {
    left: 0rem;
    display: initial;
    background-color: $background-secondary;
    //top: calc(#{$header-height} + #{$sub-header-height} + #{2 * $header-margin});
    width: $sidebar-width;
    height: auto;
    position: absolute;
    z-index: 2;
    animation-name: openMenuAnimation;
    animation-duration: 0.5s;
}

.menuButton {
    //margin-left: 1rem;
    //margin-top: 1rem;
    //margin-bottom: 1rem;
    text-indent: 1.5rem;
    border-bottom: ridge;
    border-color: $electrans-color2;
    font-weight: normal;
    font-size: x-large;
    color: $background-primary;
}

.menuButton:hover {
    background-color: $electrans-color2;
    cursor: pointer;
}

.menubar-container {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    column-gap: 3rem;
    font-size: 1.25rem;
    font-weight: bolder;
}

.content-container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.cards-container {
    margin-top: 0.5rem;
}

.cards-header {
    display: flex;
    justify-content: space-between;
    // align-items: baseline;
}

.cards-body-groups {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.cards-body-elements {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
}

// .cards-body-text-element {
//     display: flex;
//     flex-direction: column;
//     margin-left: 0.5rem;
//     margin-right: 0.5rem;
//     column-gap: ;
//     // align-items: center;
// }

.body-filters {
    display: flex;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-content: stretch;
}

.body-title {
    font-size: 1.75rem;
    color: $main-text-color;
    font-weight: bolder;
    //margin: 0.5rem;
}

.body-subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    // margin-top: 0.5rem;
}

.body-obs {
    white-space: pre-wrap;
}

.buttons-group {
    // float: right;
    display: flex;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    column-gap: 0.5rem;
    // justify-content: space-evenly;
    // align-content: flex-start;
    // flex-wrap: wrap;
}

.filters-buttons {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    // justify-content: space-evenly;
    align-content: flex-start;
    flex-wrap: wrap;
}

.modal-buttons {
    display: flex;
    margin: 1.5rem;
    justify-content: space-evenly;
}

.scrollable-table-container {
    overflow-x: auto;
}

.dropzone {
    background: #0080424b;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    border: 1px dashed $background-secondary;
    padding: 8px;
    cursor: pointer;
}

.dropzone.disabled {
    background: #bdffdf4b;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    border: 1px dashed $background-secondary;
    padding: 8px;
    color: #a0a0a0;
}

// .dropzone.textDisabled {
//     text-align: center;
// }

.body-registro-image-container {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}

.body-registros-images {
    display: inline-flex;
    width: $body-registros-images-width;
    height: $body-registros-images-height;
    border-radius: 0.25rem;
}

.image-button {
    display: flex;
    margin-top: 0.25rem;
    margin-left: 1.25rem;
}

.email-list-object {
    display: flex;
    height: auto;
    width: 25rem;
    justify-content: space-between;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.modal-form-block{
    margin-top: 2rem;
}

.image-sample {
    // display: flex;
    max-width: 100%;
    max-height: auto;
    // object-fit: contain
    // justify-content: center;
}

.image-container {
    display: flex;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa; margin: 0 0 10px; padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
}

.react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs-new-tab {
    font-size: 1.25rem;
    font-weight: bold;
    color: $main-text-color;
    //margin-top: 0.5rem;
}

.largeCheckBox {
    width: 1.5rem;
    height: 1.5rem;
}

.checkBox-form {
    margin-top: 0.5rem !important;
    display: flex;
    align-items: center;  
  
    label {
        margin-left: 0.5rem;
    }
}

.centerTextCell {
    // display: flex;
    // justify-content: center;
    text-align: center;
}

.measurementsResultText {
    font-weight: bold;
    color: #f70505;
}

.measurementsResultText.inRange {
    font-weight: bold;
    color: $electrans-color2;
}

.zebra tr:nth-child(even) {
    background-color: #e9e9e9;
    // display: flex;
    // justify-content: space-between;
}

.evaluacionImg {
    width: 50%;
    max-width: 100%;
    height: auto;
    // display: flex;
    // justify-content: center;
}
  

@include media-breakpoint-down(sm) {

    .header {
        height: $header-height-mobile;
    }

    .header-title {
        font-size: 1rem;
        max-width: calc(100% - #{$header-user-card-width-mobile});
        margin-left: $header-title-margin-mobile;
        margin-right: $header-title-margin-mobile;
    }

    .header-logo {
        display: none;
    }

    .header-text-user-card {
        display: none;
    }

    .header-user-card {
        width: $header-user-card-width-mobile;
    }

    .sub-header {
        height: $sub-header-height-mobile;
    }

    .menu-button {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }

    .breadcrumb {
        font-size: medium;
        margin-left: 0.5rem;
        font-weight: normal;
    }

    .menubar.active {
        left: 0rem;
        width: $sidebar-width-mobile;
        width: 100%;
        height: auto;
        animation-name: openMenuAnimationMobile;
        animation-duration: 0.5s;
    }

    .menubar {
        left: calc(0rem - 1rem - #{$sidebar-width-mobile});
        animation-name: closeMenuAnimationMobile;
        animation-duration: 0.25s;
    }
}

@include media-breakpoint-down(md) {
    .header-logo {
        display: none;
    }
}